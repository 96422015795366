@keyframes move-it {
  0% {
    background-position: initial;
  }
  100% {
    background-position: 100rem 0;
  }
}

html {
  min-height: 100vh;
  scrollbar-color: #5715c8 #222 !important;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: repeating-linear-gradient(135deg, #000000 0%, #1c0836 25%, #000000 50%);
  background-size: 100rem 100rem;
  animation: move-it 20s ease-in-out infinite;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
