.education {
    padding: 2rem;
    text-align: center;
    color:#FFF;
    display: grid;
    align-items: center;
    justify-content: center;
    max-width: 50rem;
}

.education-text {
    padding: 0 2rem 0 2rem;
    color: #AFABD9;
    font-size: 1.18rem;
}

.education-img {
    padding: 0.5rem;
    height: 80%;
    width: 80%;
    margin-top: 2rem;
}