.projects {
    padding: 2rem;
    text-align: center;
    color:#FFF;
    display: grid;
    align-items: center;
    justify-content: center;
}
.project-stack {
    justify-content: center;
    align-items: stretch;
    max-width: 120rem;
    animation: default-anim 0.8s ease;
    animation-fill-mode: both;
}
.project-deck {
    padding: 0 1rem 0 1rem;
    margin-top: 2rem;
}
.project-card {
    text-align: left;
    height: 100%;
}
.project-card .card-text {
    font-size: 1.05rem;
    text-align: center;
    color: #dccbff;
}

.project-img {
    float: left;
    padding: 0 1rem 1rem 0;
    width: 80px;
}

.project-screenshot {
    width: 100%;
    border: 0;
    border-radius: 1rem;
    margin: 1rem 0 2rem;
    box-shadow: 0 0 1rem black;
}

.project-link {
    padding: 0;
    max-width: 1rem;
    margin-left: 0.5rem;
    transform: translateY(-10%);
}
.project-hyperlink {
    display: inline-block;
    position: relative;
    text-decoration: none;
}
.project-hyperlink:hover {
    cursor: pointer;
}
.project-hyperlink a {
    text-decoration: none;
    color: white;
}
.project-hyperlink:hover a {
    color: #5715c8;
}
.project-hyperlink:hover .project-link {
    transform: translateY(-30%);
    transition: 0.1s ease-in-out;
}
.project-hyperlink:after {
    content: '';
    position: absolute;
    width: calc(100% - 1.5rem);
    transform: scaleX(0);
    height: 2px;
    bottom: 0;
    left: 0;
    background-color: white;
    transform-origin: bottom right;
    transition: transform 0.15s ease-out;
}
.project-hyperlink:hover:after {
    transform: scaleX(1);
    transform-origin: bottom left;
    background-color: #5715c8;
}

.project-icons {
    padding: 0 0.3rem 0 0.3rem;
    max-width: 3.2rem;
    transition: scale 0.05s ease-out;
    float:bottom;
}
.project-icons:hover {
    scale: 1.1;
}

.project-buttons {
    border: 0;
    padding: 0;
    background: rgba(0, 0, 0, 0);
}
.project-buttons:focus {
    outline: none;
}

.project-modal .modal-content {
    background-color: #1f1f1f;
    width: auto;
}

.project-video .modal-content{
    background-color: #1f1f1f;
}
.modal-dialog {
    justify-content: center;
    scrollbar-color: #CCCECF #1F1F1F !important;
    scrollbar-width: thin !important;
}
.project-modal-img {
    max-height: calc(100vh - 90px);
    overflow-y: auto;
}

.carousel-control-prev-icon {
    width: 2rem;
    height: 2.71828183rem;
    background-color: rgba(255, 255, 255, 0.2);
    border-radius: 2rem;
    background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%23000' viewBox='0 0 8 8'%3E%3Cpath d='M5.25 0l-4 4 4 4 1.5-1.5-2.5-2.5 2.5-2.5-1.5-1.5z'/%3E%3C/svg%3E") !important;
}
.carousel-control-next-icon {
    width: 2rem;
    height: 2.71828183rem;
    background-color: rgba(255, 255, 255, 0.2);
    border-radius: 2rem;
    background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%23000' viewBox='0 0 8 8'%3E%3Cpath d='M2.75 0l-1.5 1.5 2.5 2.5-2.5 2.5 1.5 1.5 4-4-4-4z'/%3E%3C/svg%3E") !important;
}

.card > .list-group:last-child {
    border-bottom-width: 0;
    border-bottom-right-radius: 1.25rem;
    border-bottom-left-radius: 1.25rem;
}