.footer {
    padding: 1rem;
    text-align: center;
    color:white;
    background-color: #5715c83a;
}

.footer-icons {
    margin-bottom: 1rem;
    padding: 1.2rem;
    max-width: 5rem;
    transition: scale 0.08s ease-out;
}
  
.footer-icons:hover {
    scale: 1.1;
}