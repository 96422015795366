@keyframes default-anim {
    from {
      opacity: 0;
      transform: translateY(-20px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
}

.card {
  border-radius: 1.25rem;
  border: 0;
}

.bg-dark {
  background-color: #5715c83a !important;
}