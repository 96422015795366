.welcome {
  background-size: cover;
  min-height: 90vh;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  color: #AFABD9;
  max-width: max-content;
  margin:auto;
}

.pfp {
  padding: 1rem;
  max-height: 67vh;
  max-width: 100%;
  animation: pfp-anim 1s ease;
  animation-fill-mode: both;
}

@keyframes pfp-anim {
  from {
    opacity: 0;
    transform: translateX(-10%);
  }
  to {
    opacity: 1;
    transform: translateX(0%);
  }
}

.intro-text {
  font-size: 18px;
  animation: intro-anim 0.6s ease 0.2s;
  animation-fill-mode: both;
  min-width: max-content;
}

@keyframes intro-anim {
  from {
    opacity: 0;
    transform: translateY(-10%);
  }
  to {
    opacity: 1;
    transform: translateY(0%);
  }
}

.home-icons {
  margin-bottom: 1rem;
  padding: 1.2rem;
  max-width: 5rem;
  animation: home-icons-anim 0.8s ease 0.4s;
  animation-fill-mode: both;
  transition: scale 0.08s ease-out;
}

.home-icons:hover {
  scale: 1.1;
}

@keyframes home-icons-anim {
  from {
    opacity: 0;
    transform: translateY(-20%);
  }
  to {
    opacity: 1;
    transform: translateY(0%);
  }
}