.experience {
    padding: 2rem;
    text-align: center;
    color:#FFF;
    display: grid;
    align-items: center;
    justify-content: center;
}

.experience-card {
    padding: 0.5rem;
    max-width: 38rem;
}

.experience-card:hover {
    box-shadow: 0.75rem 0.75rem black;
}

.experience-text {
    padding: 0 2rem 0 2rem;
    color: #dccbff;
    max-height: 60rem;
    max-width: 38rem;
    font-size: 1.18rem;
}

.experience-img {
    margin-bottom: 1rem;
    margin-top: 2rem;
    max-width: 100%;
    max-height: 30rem;
}

.experience-img:hover {
    box-shadow: 0.75rem 0.75rem black;
}

.experience-hyperlink {
    display: inline-block;
    position: relative;
    color: white;
}
.experience-hyperlink:hover {
    color: #5715c8;
    text-decoration: none;
}
.experience-hyperlink:after {
    content: '';
    position: absolute;
    width: 100%;
    transform: scaleX(0);
    height: 1px;
    bottom: 0;
    left: 0;
    background-color: white;
    transform-origin: bottom right;
    transition: transform 0.15s ease-out;
}
.experience-hyperlink:hover:after {
    transform: scaleX(1);
    transform-origin: bottom left;
    background-color: #5715c8;
}