.brand-icon {
    padding: 0rem;
    margin-right: 1rem;
    max-width: 3rem;
}

.nav-item {
    width: 100%;
    font-size: 1.25rem;
    display: inline-block;
    position: relative;
}

.nav-item:after {
    content: '';
    position: absolute;
    width: calc(100% - 1rem);
    transform: scaleX(0);
    height: 2px;
    bottom: 0;
    left: .5rem;
    background-color: #5715c8;
    transform-origin: bottom right;
    transition: transform 0.25s ease-out;
}
.nav-item:hover:after {
    transform: scaleX(1);
    transform-origin: bottom left;
}

.navbar-dark .navbar-nav .nav-link {
    color: #AFABD9;
    padding: 0 .5rem;
}

.navbar-dark .navbar-nav .nav-link:hover {
    color: #dccbff;
}

.navbar-dark .navbar-nav .nav-link:focus {
    color: #dccbff;
}